import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Switch from "react-switch";
import SweetAlert from "react-bootstrap-sweetalert"; // Import SweetAlert
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import ls from "localstorage-slim";
import { changeModeLogout,switchModeServer } from "../../../actions/auth";

ls.config.encrypt = true;

const ModeTonggle = ({ changeModeLogout,switchModeServer, authMode,auth, history }) => {
  const [rSelected, setRSelected] = useState(authMode);
  const [showSwitchConfirmation, setShowSwitchConfirmation] = useState(false);
  const [switchToProduction, setSwitchToProduction] = useState(false);
  const [switchedToModeMessage, setSwitchedToModeMessage] = useState("");

  const onRadioBtnClick = () => {
    setShowSwitchConfirmation(true);
    setSwitchToProduction(!switchToProduction);
  };

  const handleSwitchConfirmation = async(confirmed) => {
    if (confirmed) {
      if (rSelected === 1) {
        let data = {email:auth.user.email,sandbox:false}
        await switchModeServer(data)
        await changeModeLogout(2);
        setSwitchedToModeMessage("Switched to test account successfully!");
        ls.set("apikey", ls.get("sandboxapi"));
        ls.set("secrettoken", ls.get("tokenTest"));
      } else {
        let data = {email:auth.user.email, sandbox:true}
        await switchModeServer(data)
        await changeModeLogout(1);
        setSwitchedToModeMessage("Switched to production account successfully!");
        ls.set("apikey", ls.get("liveapi"));
        ls.set("secrettoken", ls.get("tempsecret"));
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    setShowSwitchConfirmation(false);
  };

  useEffect(() => {
    setRSelected(authMode);
  }, [authMode]);

  const handleCancel = () => {
    setShowSwitchConfirmation(false);
  };

  return (
    <Fragment>
      <div
        className={
          rSelected === 2
            ? "raised-mode-toggle toggleContainer1"
            : "raised-mode-toggle toggleContainer2"
        }
      >
        <small
          className={
            rSelected === 2 ? "toggleTextActive" : "toggleTextInActive"
          }
          style={{ width: "80px", fontSize: "14px" }}
        >
          Test mode
        </small>
        <Switch
          onColor="#fcaf10"
          handleDiameter={13}
          uncheckedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 2)"
          height={15}
          width={32}
          onChange={() => onRadioBtnClick()}
          checked={rSelected === 2}
        />
      </div>

      {/* SweetAlert confirmation */}
      <SweetAlert
        warning
        showCancel
        show={showSwitchConfirmation}
        title={`Do you want to switch to ${
          rSelected === 1 ? "test" : "production"
        } account?`}
        onConfirm={() => handleSwitchConfirmation(true)}
        onCancel={() => handleCancel()}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        cancelBtnText="No"
        confirmBtnText="Yes"
        style={{ overflow: "visible" }}
      />

      {/* SweetAlert for displaying mode switch message */}
      {switchedToModeMessage && (
        <SweetAlert
          success
          title={switchedToModeMessage}
          onConfirm={() => setSwitchedToModeMessage("")}
        />
      )}
    </Fragment>
  );
};

ModeTonggle.propTypes = {
  changeModeLogout: PropTypes.func.isRequired,
  switchModeServer: PropTypes.func.isRequired,
  auth:PropTypes.object.isRequired,
  authMode: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  authMode: state.auth.authMode,
});

export default connect(mapStateToProps, { changeModeLogout,switchModeServer })(ModeTonggle);
